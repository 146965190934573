// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  timeStamp: '07/23/2024 10:54:11 AM IST',
  production: 'false',
  apiURL: {
    baseUrl: 'https://api-qa.futuretalentplatform.com',
    aiccBaseUrl: 'https://api-qa.futuretalentplatform.com/aicc',
    tenantManagement: 'https://api-qa.futuretalentplatform.com/tenant',
    userManagement: 'https://api-qa.futuretalentplatform.com/user',
    captcha: 'https://captcha.dev.futuretalentplatform.com',
  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.*/
